import * as React from 'react'
import styled from 'styled-components'
import Navbar from './components/navbar'
import Titlebar from './components/titlebar'

const StyledSiteContainer = styled.div`
  margin: auto;
  max-width: 70rem;
  font-family: Taviraj;
  --highlight-color: red;
  --primary-text-color: #777744;
  --primary-text-color-translucent: rgba(77, 77, 44, 0.8);
  h1, h2, h3, h4, h5, h6, p {
    color: var(--primary-text-color);
  }
  a {
    text-decoration: none;
  }
`

const StyledHeading = styled.h1`
  margin-top: 1.5em;
  text-align: center;
`

const BaseLayout = ({ pageTitle, children }) => {
  return (
    <StyledSiteContainer>
      <Titlebar />
      <Navbar />
      <main>
        <StyledHeading>
          { pageTitle }
        </StyledHeading>
        { children }
      </main>
    </StyledSiteContainer>
  )
}

export default BaseLayout
