import React from "react"
import { graphql } from "gatsby"
import { BaseLayout } from "../layouts"

export default function MediumPage ({ data }) {
  const medium = data.allMdx.edges[0].node.frontmatter.medium
  return (
    <BaseLayout>
      <div>
        <h1>{medium}</h1>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
query ($medium: String) {
    allMdx(filter: {frontmatter: {medium: {eq: $medium}}}) {
      edges {
        node {
          frontmatter {
            title
            slug
            dimensions
            medium
            image {
              childImageSharp {
                gatsbyImageData(
                  # width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`