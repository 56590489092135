import * as React from 'react'
import styled from 'styled-components'
import BaseLayout from './baseLayout'

const ImageContainer = styled.div`
  display: flex;
  width: auto;
  padding: 1em;
  align-items: center;
  justify-content: center;
`

const FlexSpacer = styled.div`
  flex: 1 0 0;
`

const FlexImageContainer = styled.div`
  flex: 0 1 auto;
`

const ContentContainerFlex = styled.div`
  padding: 1em;
`

const PhotoCentricLayout = ({ pageTitle, image, children }) => {
    return (
        <BaseLayout pageTitle={ pageTitle }>
          <ImageContainer>
            <FlexSpacer/>
            <FlexImageContainer>
              { image }
            </FlexImageContainer>
            <FlexSpacer/>
          </ImageContainer>
          <ContentContainerFlex>
            { children }
          </ContentContainerFlex>
        </BaseLayout>
    )
}

export default PhotoCentricLayout