import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'


const StyledLink = styled(Link)`
    color: black
`
const StyledListItem = styled.li`
    padding-right: 2rem;
    flex: 1 1 auto;
    text-align: center;
`
const StyledList = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-left: 0;
`
const StyledNav = styled.nav`
    border-top: 1px solid black;
    border-bottom: 1px solid black;
`

const Navbar = () => {
    const navLinks = [
        { text: 'Collections', link: '/' },
        { text: 'About the Artist', link: '/about' },
        { text: 'Contact', link: '/contact'}
    ]
    return (
        <StyledNav>
            <StyledList>
            {
                navLinks.map( ({ text, link }) => (
                    <StyledListItem>
                        <StyledLink to={ link }>
                            { text }
                        </StyledLink>
                    </StyledListItem>
                ))
            }
            </StyledList>
        </StyledNav>
    )
} 

export default Navbar