import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const StyledHeader = styled.header`
    font-size: 3rem;
    color: var(--primary-text-color);
    font-weight: 700;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
`

const Titlebar = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const title = data.site.siteMetadata.title

  return <StyledHeader>{ title }</StyledHeader>
}

export default Titlebar